<script>
    import {fade} from 'svelte/transition';
    import {_} from "svelte-i18n";
    import MIcon from "./MIcon.svelte";
    import {eventNotes} from "../stores";

    const isMobile = ((window.innerWidth > 0) ? window.innerWidth : screen.width) <= 600;    

    export let eventName;
</script>

<dialog class={isMobile ? "bottom" : ""} id="editEventNotes">
    <div transition:fade|local style="height: 100%;">
        <nav>
            <h5 class="max">{eventName}</h5>
            <button class="circle transparent" data-ui="#editEventNotes">
                <MIcon>close</MIcon>
            </button>
        </nav>
        <div class="field border textarea max" style="height: 400px;">
            <textarea bind:value={$eventNotes[eventName]} placeholder="{$_('notesSheet.yourNotes', {default: 'A place for your notes'})}"/>
        </div>
    </div>
</dialog>
