<script>
    import { convertTimeReadable } from "../utils/dates";
    import {_icon_literal} from "../utils/icon.js";
    import {_} from "svelte-i18n";

    const iconMap = {
        'Лабораторные': _icon_literal("science"),
        'Лекции': _icon_literal("headset_mic"),
        'Практические': _icon_literal("construction"),
        'Курсовая работа': _icon_literal("book"),
        'Зачет': _icon_literal("edit_document"),
        'Балльно-рейтинговый контроль': _icon_literal("edit")
    };

    export let event;
    export let activeEventNameNotes;
</script>

<style>
    div[disabled=true] {
        opacity: 0.3;
    }
</style>

<div class="row" disabled={event.wrongSubgroup || event.disabled}>
    <h5 class="s small primary-text max" style="margin-top: 25px;">{event.description}</h5>
    <h5 class="m l primary-text max" style="margin-top: 25px;">{event.description}</h5>
    {#if event.id}
    <div>
        <i class="left-padding tiny-padding" class:notranslate={true} translate="no">{_icon_literal("bookmark_add")}</i>
        <div class="tooltip top">{$_('page.schedule.customEventAlert', {default: "Does not reflect the current schedule"})}</div>
    </div>
    {/if}
    <button class="circle transparent border small" data-ui="#editEventNotes" on:click={() => {activeEventNameNotes = event.description}}>
        <i class="left-padding tiny-padding" class:notranslate={true} translate="no">{_icon_literal("edit_note")}</i>
        <div class="tooltip top">{$_('page.schedule.notes', {default: "Notes"})}</div>
    </button>
    <div>
        <i class="left-padding tiny-padding" class:notranslate={true} translate="no">{iconMap[event.typeWorkName]}</i>
        <div class="tooltip left">{$_(`page.schedule.${event.typeWorkName}`, {default: event.typeWorkName})}</div>
    </div>
</div>
<div class="row top-align" style="margin-top: 10px;" disabled={event.wrongSubgroup || event.disabled}>
    <div>
        <h5 class="s small">{convertTimeReadable(event.timeBegin)}</h5>
        <h5 class="s small">{convertTimeReadable(event.timeEnd)}</h5>
        <h6 class="m l">{convertTimeReadable(event.timeBegin)}</h6>
        <h6 class="m l">{convertTimeReadable(event.timeEnd)}</h6>
    </div>
    <div class="max">
        <h5 class="s small secondary-text ">{event.fio}</h5>
        <h6 class="m l secondary-text">{event.fio}</h6>
    </div>
    <nav class="bottom-align vertical" style="padding-top: 0px">
        <div class="row" style="gap: 0.25rem;">
            <h5 class="s small">{event.room}</h5>
            <h6 class="m l">{event.room}</h6>
            {#if event.building}
                <h5 class="s small">({event.building})</h5>
                <h6 class="m l">({event.building})</h6>
            {/if}
        </div>
        {#if event.subGroup}
            <h6 class="small bold" style="margin: 0;">({event.subGroup})</h6>
        {/if}
    </nav>
</div>